.all {
    overflow-x: hidden!important;
    max-width: 100vw!important;
}

.fam {
    background-color: rgb(255, 255, 255)!important;
    border-color: #ffffff!important;
    color: black
}

.fam:hover {
    cursor: pointer;
    background-color: white;
    color: black;
    border-radius: 30px;  
    border-color: #ffffff; 
    transform: translateY(0px)!important;
    box-shadow: none!important;
}

.fam:focus {
    color:black;
}
.small-screens {
    display: none;
}

.md-s {
    display:none
}

@media screen and (max-width: 768px) {
    .md-s {
        padding-left: 95px;
    }
    .magic {
        margin-left: -95px!important
    }
}

@media screen and (max-width: 767px) {
    .md-s {
        padding-left: 0;
    }
    .magic {
        margin-left: 0!important
    }
}


.sm-s {
    display: none;
}

@media screen and (max-width:1200px) {
    .md-s {
        display: block;
    }
    .lg-s {
        display: none;
    }
}

@media screen and (max-width: 630px) {
    .md-s {
        display: none;
    }
    .sm-s {
        display: block;
    }
}

.large-screens {
    padding-top: 100px;
}

@media screen and (max-width:992px) {
    .large-screens {
        display: none;
    }  
    .small-screens {
        display: block;
    }  
}

.colored-bg {
    background-image: url("./shape.png");
}

.landing {
    height: 100vh;
    width: auto;
    margin: 0;
    background-size:cover;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("./tausta.jpg");
    background-position: center;
}

/* @media screen and (max-width: 1200px) {
    .landing {
        margin-left: -200px;
    }
} */

/*
@media screen and (max-width: 850px) {
    .landing {
        background-image: url("./cropped_bg_3.png");
    }
}

@media screen and (max-width: 768px) {
    .landing {
        text-align: center;
        background-image: url("./cropped_bg_4.png");
    }
} */

.img-holder{
    width: 50%;
    height:50%;
    margin-top: -30%;
    margin-left: 55%
}

/* .button-row {
    display: flex;
} */

.button-div-1 {
    width:50%;
    text-align: end;
}

.button-div-2 {
    width:50%;
    text-align: left;
}

@media screen and (max-width: 1286px) {
    .button-div-1 {
        width:50%;
    }
    
    .button-div-2 {
        width:50%;
    }
}

.sub-header {
    font-size: 20px;
}
.content {
    width:60%;
    color:rgb(255, 255, 255)
}

.content h1 {
    font-size: 50px;
}

@media screen and (max-width: 768px) {
    .content h1 {
        font-size: 32px;
    }
    .content {
        width:90%;
        padding-left: 0;
        justify-content: center;
        text-align: center;
    }
    .sub-header {
        font-size: 16px;
    }
}

.btn-primary {
    background-color: rgb(39, 120, 39);
    border-color: rgb(39, 120, 39);
    transition: all 0.3s ease 0s;
    border-radius: 30px;
    /* box-shadow: 15px 15px 15px 15px rgba(46, 229, 157, 0.4); */
}

.btn-primary:active {
    background-color: rgb(39, 120, 39);
    border-color: rgb(39, 120, 39);
    outline-color: green;
}

.btn-primary:focus {
    background-color: rgb(39, 120, 39);
    border-color: rgb(39, 120, 39);
    outline: none!important;
}

.btn-primary:hover {
    background-color: rgb(21, 76, 21);
    border-color: rgb(21, 76, 21);
}

.only-outline {
    background-color: transparent;
    color: white;
    border-radius: 30px;  
    border-color: #ffffff; 
    transition: all 0.3s ease 0s;
}

.only-outline:focus {
    color: black
}

.only-outline-green {
    border-color: rgb(39, 120, 39);
    background-color: rgb(39, 120, 39);
    color: white;
    width: 300px;
    transition: all ease in 0.3s;  
}

@media screen and (max-width: 350px) {
    .only-outline-green {
        width: 85%
    }
}

.only-outline-green:hover {
    background-color: rgb(21, 76, 21);
    border-color: rgb(21, 76, 21);
}

.only-outline:hover {
    background-color: white;
    color: black;
    border-radius: 30px;  
    border-color: #ffffff; 
    transform: translateY(0px)!important;
    box-shadow: none!important;
}

.only-outline:active {
    background-color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255);
    outline-color: #ffffff;
}

.only-outline:focus {
    background-color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255);
    outline-color: #ffffff;
}


@media screen and (max-width:400px) {
    .button-div-1, .button-div-2 {
        width: 90%;
        margin-top: 15px;
        text-align: center;
    }
    .only-outline, .fam {
        width: 90%  
    }
}

.text-p {
    color: rgb(39, 120, 39)
}

.contact-us {
    width: 300px;
}
.holder {
    width: 200px;
    height: 200px;
    border-radius: 30px;
}

.colored-div-1 {
    width: 200px;
    height: 200px;
    border-radius: 30px;
    background-color: rgba(31, 237, 31, 0.2);
    transition: all ease-in 0.3s;
    margin-left: -10px!important;
}

.colored-div-1:hover {
    background-color: rgba(31, 237, 31, 0.4);
}

.picture-container {
    width: 200px;
    height: 200px;
    border-radius: 30px;
    position: relative;
}

.image {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    position: absolute;
    margin-left: -12px;
    display: block;
}

.hover {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    position: absolute;
    top: 0;
    left:0;
    background: rgba(0,0,0,0.6);
    color: #ffffff;
    padding: 20% 0;
    opacity: 0;
    transition: opacity 0.5s
}

.hover:hover {
    opacity: 1;
}

.padding-adder-1 {
    padding-left: 20px!important;
    margin-bottom:15px!important
}

.padding-adder-2 {
    padding-left: 100px!important;
    margin-bottom: 15px!important
}
.padding-adder-3 {
    padding-left: 40px!important;
}

.padding-adder-4 {
    padding-left: 100px!important;
}

.logo-sizing {
    width: 100px
}

@media screen and (max-width:1400px) {
    .holder {
        width: 180px;
        height: 18px;
        border-radius: 30px;
    }
    .picture-container {
        width: 180px;
        height: 180px;
        border-radius: 30px;
        position: relative;
    }
    .colored-div-1 {
        width: 180px;
        height: 180px;
        border-radius: 30px;
        background-color: rgba(31, 237, 31, 0.2);
        transition: all ease-in 0.3s;
        margin-left: -10px!important;
    }
    .padding-adder-4 {
        padding-left: 50px!important;
    }
}
@media screen and (max-width:1200px) {
    .padding-adder-4 {
        padding-left: 20px!important;
    }
    .padding-adder-2 {
        padding-left: 60px!important;
    }
}

@media screen and (max-width:992px) {
    .padding-adder-4 {
        display: none;
    }
    
    .only-outline-green { 
        margin-left: 0;  
    }
}

.p-left {
    margin-left: 13%;
}

@media screen and (max-width: 768px) {
    .p-left {
        margin-left: -30px;
        margin-right: -50px;
        margin-bottom: -50px;
    }

    .p-b {
        padding-bottom: 100px!important;
    }
}

@media screen and (max-width: 768px) {
    .p-left {
        margin-left: -30px;
        margin-right: -50px;
        margin-bottom: -50px;
    }

    .p-b {
        padding-bottom: 100px!important;
    }
}

.company-card-trinode {
    width: 250px;
    height: 350px;
    border-radius: 15px;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.15);
    background-color: white;
    transition: all ease-in 0.3s;
}

.company-card-growpolis {
    width: 250px;
    height: 350px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.15);
    transition: all ease-in 0.3s;
}

.company-card-feelado {
    width: 250px;
    height: 350px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.15);
    transition: all ease-in 0.3s;
}

.company-card-veri {
    width: 250px;
    height: 350px;
    border-radius: 15px;
    background-color: white;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.15);
    transition: all ease-in 0.3s;
}

.company-link-trinode {
    text-decoration: none;
    color: rgb(39, 120, 39);
    transition: all ease-in 0.3s;
}

.company-link-growpolis {
    text-decoration: none;
    color: rgb(38, 103, 88);
    transition: all ease-in 0.3s;
}

.company-link-feelado {
    text-decoration: none;
    color: rgb(41, 184, 255);
    transition: all ease-in 0.3s;
}

.company-link-veri {
    text-decoration: none;
    color: rgb(111, 111, 111);
    transition: all ease-in 0.3s;
}


.company-card-trinode:hover {
    background-color: rgb(39, 120, 39);
    color: #ffffff;
    /* box-shadow: 0px 0px 15px 20px rgba(1, 189, 45, 0.4); */
}

.company-card-trinode:hover .company-link-trinode {
    color: #ffffff;
}

.company-card-growpolis:hover .company-link-growpolis {
    color: #ffffff;
}

.company-card-feelado:hover .company-link-feelado {
    color: #ffffff;
}

.company-card-veri:hover .company-link-veri {
    color: #ffffff;
}
.trinode {
    width: 100px;
    height: 100px;
    background-image: url("./trinode_logo_small.png");
    background-size: cover;
    margin-left: 75px;
    margin-bottom: 50px;
    transition: all ease-in 0.1s;
}

.link-align {
    margin-top: 50px;
}

.company-card-trinode:hover .trinode {
    background-image: url("./trinode_white.png");
}

.growpolis {
    width: 150px;
    height: 35px;
    background-image: url("./gp_logo_bg.png");
    background-size: cover;
    margin-left: 50px;
    margin-bottom: 92px;
    transition: all ease-in 0.1s;
}

.company-card-growpolis:hover .growpolis {
    background-image: url("./gp_logo_white.png");
    width: 150px;
    height: 30px;
    padding-left: 10px;
    margin-bottom: 97px;
}

.company-card-growpolis:hover {
    background-color: rgb(38, 103, 88);
    color: #ffffff;
    /* box-shadow: 0px 0px 15px 20px rgba(40, 142, 101, 0.4); */
}

.feelado {
    width: 100px;
    height: 100px;
    background-image: url("./F.png");
    background-size: cover;
    margin-left: 75px;
    margin-bottom: 40px;
    transition: all ease-in 0.1s;
}

.link-align-feelado {
    margin-top: 30px
}

.company-card-feelado:hover {
    background-color: rgb(41, 184, 255);
    color: #ffffff;
    /* box-shadow: 0px 0px 15px 20px rgba(32, 196, 255, 0.4); */
}


.company-card-veri:hover {
    background-color: rgb(111, 118, 128);
    color: #ffffff;
    /* box-shadow: 0px 0px 15px 20px rgba(75, 76, 76, 0.4); */
}

.company-card-feelado:hover .feelado {
    background-image: url("./F_white.png");
    width: 60px;
    height: 100px;
    margin-left: 95px;
}

.veri {
    width: 130px;
    height: 40px;
    background-image: url("./veri_logo.png");
    background-size: cover;
    margin-left: 60px;
    margin-bottom: 90px;
    transition: all ease-in 0.1s;
}

@media screen and (max-width: 630px) {
    .trinode {
        margin-left: 65px;
    }
    .growpolis {
        margin-left: 40px;
    }
    .feelado {
        margin-left: 65px;
    }
    .company-card-feelado:hover .feelado {
        margin-left: 85px;
    }
}

.company-card-veri:hover .veri {
    background-image: url("./veri_white.png");
    width: 135px;
    height: 40px;
}

hr.h-divider {
    height: 2px;
    background-color: rgb(175, 175, 175);
}

hr.our-story {
    height: 5px;
    width: 150px;
    color: #19a43e;
    opacity: 1;
}

hr.our-customers {
    height: 5px;
    width: 350px;
    color: #19a43e;
    opacity: 1;
}

hr.contact {
    height: 5px;
    width: 150px;
    color: #ffffff;
    opacity: 1;
}

hr.footer-hr {
    height: 1px;
    background-color: rgb(175, 175, 175);
}

.sub-h {
    font-size: 20px;
}

.dot {
    width: 7px;
    margin-top: 15px;
    margin-left: 2px;
}

.green-bg {
    background-color: #32a852;
}

.m-rmr {
    margin-bottom: 0!important;
}

.contact-form {
    width: 90%;
    height: 595px;
    background-color: #ffffff;
    border-radius: 30px;
    margin-left: 10%;
    box-shadow: 5px 5px 5px 7px rgba(0, 0, 0, 0.1), 10px 6px 20px 0 rgba(0, 0, 0, 0.1);

}

@media screen and (max-width: 992px) {
    .p-rmr {
        margin-top: -48px!important;
    }
    .contact-form {
        margin-left: 5%;
        margin-top: 40px;
    }
}


@media screen and (max-width: 480px) {
    .contact-form {
        margin-left: 0;
        width: 100%;
    }
    .p-r {
        padding-right: 5px!important;
        padding-left: 5px!important;
        margin-left:0;
        margin-right: 0!important;
    }
}

@media screen and (max-width: 384px) {
    .contact-form {
        margin-left: 0;
        width: 100%;
        height: 600px
    }
}

.form-submit {
    width: 90%;
    height: 50px;
    border-radius: 10px;
    padding-left: 5%;
    padding-bottom: 0.5%;
    border-width: 1px;
    outline: none;
    color: rgb(66, 66, 66);
    background-color: rgb(255, 255, 255);
    border-style: none;
  }

  .form-submit-textarea {
    width: 90%;
    height: 100px;
    border-radius: 10px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 0.5%;
    padding-top: 2%;
    border-width: 1px;
    outline: none;
    color: rgb(66, 66, 66);
    background-color: rgb(255, 255, 255);
    border-style: none;
  }

  .form-button {
      width: 70%;
  }

  .grey-bg {
      background-color: rgb(240, 240, 240);
  }

  .trinode-grey {
      width: 35px;
      margin-right: 10px;
  }

  .footer-p {
      font-size: 25px;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  .gray-bg {
      background-color:rgb(248,248,248)
  }

  .footerLink {
    color: gray;
    text-decoration: none;
  }
  
  .footerLink:hover {
    color:rgb(39, 120, 39)
  }

  .circle {
    width: 25px;
    height:25px;
    border-style: solid;
    border-radius: 12.5px;
    border-color: grey;
    border-width: 1px;
  }

  .mover {
      margin-left: 4px;
      margin-top: -5px;
  }

  .mover-mail {
    margin-left: 4px;
    margin-top: -5px
}

.negative-p {
    margin-bottom: -15px!important
}
.negative-p-2 {
    margin-bottom: 0px!important
}


textarea { resize: none; }

.service-card {
    width: 370px;
    height: 200px;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(218, 218, 218);
    border-width: 1px;
    transition: all ease-in 0.3s;
}


.service-card h5 {
    transition: all ease-in 0.3s
}

.icons {
    margin-top: 85%;
}

.align-center {
    align-items: center;
}

.service-card:hover {
    border-color: rgba(39, 120, 39, 0.6);
    box-shadow: 5px 5px 12px -2px rgba(0,0,0,0.1)
}

.holder-2 {
    width: 370px;
    height: 200px;
    border-radius: 5px;
    margin-left:3.5rem
}

@media screen and (max-width:992px) {
    .holder-2 {
        width:100%;
        height: auto;
        margin-left: auto;
        padding-top: 15px!important;
    } 
    
    .service-card {
        width: 100%;
        height: auto;
    }

    .icons {
        margin-top: 17%;
    }
}

@media screen and (max-width:767px) {
    .icons {
        margin-top: 40%;
    }
}

@media screen and (max-width:576px) {
    .icons {
        margin-top: 7vw
    }
}

@media screen and (max-width:468px) {
    .icons {
        margin-top: 10vw
    }
}

@media screen and (max-width:400px) {
    .icons {
        margin-top: 15vw
    }
}

@media screen and (max-width:354px) {
    .icons {
        margin-top: 20vw
    }
}

.service-card:hover h5 {
    color:rgb(39, 120, 39)
}

@media screen and (min-width: 992px) {
    .test-1,.test-2,.test-3 {
        display: none;
    }
}

.min {
    margin-bottom:-1px;
    background-color: rgb(248, 248, 248);

}

.test-1, .test-2, .test-3 {
    overflow: hidden;
    float: none; 
    width:2500px;
    max-width: 2500px;
    margin-left: -5vw;
}
.test-2 {
    margin-left: -10vw
}

.test-3 {
    margin-left: -50vw
}

@media screen and (max-width: 800px) {
    .test-3 {
        margin-left: -80vw
    }
}

@media screen and (max-width: 650px) {
    .test-3 {
        margin-left: -100vw
    }
}

@media screen and (max-width: 590px) {
    .test-3 {
        margin-left: -150vw
    }
    .test-1 {
        margin-left: -60vw;
    }
}

@media screen and (max-width: 470px) {
    .test-3 {
        margin-left: -190vw
    }
}

@media screen and (max-width: 404px) {
    .test-3 {
        margin-left: -240vw
    }
}


@media screen and (max-width: 368px) {
    .test-1 {
        margin-left: -100vw
    }
}

@media screen and (max-width: 352px) {
    .test-2 {
        margin-left: -50vw
    }
}

@media screen and (max-width: 344px) {
    .test-3 {
        margin-left: -280vw
    }
}

.form-shadow {
    box-shadow:5px 5px 7px 0px rgba(0,0,0,0.15);
    background-color: rgb(250, 250, 250);
    transition: all ease 0.3s;
    border-width: 2px;
}

.form-shadow:focus {
    box-shadow:5px 5px 7px 0px rgba(32, 140, 23, 0.3);
    border-color: rgba(39, 120, 39, 0.1);
    border-style: solid;
    border-width: 1px;
    background-color: rgba(31, 237, 31, 0.1);
}


.error:focus {
    box-shadow:5px 5px 7px 0px rgba(140, 23, 23, 0.3);
    border-color: rgba(120, 39, 39, 0.1);
    border-style: solid;
    border-width: 1px;
    background-color: rgba(237, 31, 31, 0.1);
}

.error {
    box-shadow:5px 5px 7px 0px rgba(140, 23, 23, 0.3);
    border-color: rgba(120, 39, 39, 0.1);
    border-style: solid;
    border-width: 1px;
    background-color: rgba(237, 31, 31, 0.1);
}

.mover {
    position: absolute;
    margin-top:-55px;
    margin-left: 435px;
}

.warning-p {
    margin-left: -190px;
    margin-bottom: 0;
    margin-top: -5px;
    font-size: 14px;
}

@media screen and (max-width: 1399px) {
    .mover {
        margin-left: 355px;
    }

    .warning-p {
        margin-left: -120px;
    }

}

@media screen and (max-width: 1199px) {
    .mover {
        margin-left: 280px;
    }

    .warning-p {
        margin-left: -60px;
    }
}

@media screen and (max-width: 991px) {
    .mover {
        margin-left: 480px;
    }

    .warning-p {
        margin-left: -230px;
    }
}

@media screen and (max-width: 767px) {
    .mover {
        margin-left: 330px;
    }

    .warning-p {
        margin-left: -100px;
    }
}

@media screen and (max-width: 575px) {
    .mover {
        margin-left: 72%;
    }

    .warning-p {
        margin-left: 0;
    }
}

@media screen and (max-width: 380px) {
    .mover {
        margin-left: 70%;
    }
}

@media screen and (max-width: 350px) {
    .mover {
        margin-left: 67%;
    }
}

.footer-l {
    text-decoration: none;
}

.br-r-1 {
    display: inline-block;
    width:50%;
    text-align: right;
}

.br-r-2 {
    display: inline-block;
    width:50%;
    text-align: left;
}

@media screen and (max-width: 1200px) {
    .nav-link {
        padding-left: 15px!important;
    }
    .navbar-toggler {
        margin-right: 15px!important;
    }
}

.move-left {
    margin-left: -6%!important;
}